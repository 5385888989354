.breed-age-sex-list {
  display: flex;
}

.breed-age-sex-list li {
  padding-right: 20px;
}

.pet-name {
  font-size: 32px;
  margin-bottom: 10px;
}

.pet-pic {
  width: 500px;
}

.info {
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  align-items: center;
}

.info p {
  margin: 10px 0;
}

.tabContent button {
  margin-top: 10px;
}

.pet {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  margin: 30px 30px 0 30px;
}

@media screen and (max-width: 475px) {
  .tabContent button {
    margin-top: 0;
  }

  .pet-pic {
    width: 300px;
  }
}
