* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  background-image: none;
  height: 100vh;
  font-family: "Quicksand", sans-serif;
}

/* Header */
header {
  height: 100px;
  background-color: black;
  color: white;
  padding: 4px;
}

header > p {
  text-transform: uppercase;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
}

.navbar {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.navbar > li {
  display: inline;
  padding: 0 10px 0 10px;
  font-size: 16px;
  width: 100%;
}

.navbar > li > a {
  color: black;
}

.navbar > li > a:hover {
  background-color: black;
  color: white;
}

.nav {
  padding: 8px;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 7vh;
  padding-left: 30px;
  padding-right: 30px;
}

.HomePage {
  color: white;
  background: black;
  height: 100vh;
}

.HomePage > img {
  width: 25vw;
}

.HomePage > p {
  width: 60vw;
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 200;
}

.HomePage > h1 {
  font-size: 70px;
}

button {
  background-color: white;
  width: 15vw;
  height: 5vh;
  font-size: 20px;
}

button:hover {
  background-color: pink;
}

.pet_story {
  margin-top: 20px;
  background-color: lightpink;
  width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pet-pic_story {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 500px;
}

.title {
  color: white;
}

@media screen and (max-width: 475px) {
  .HomePage > h1 {
    font-size: 25px;
  }

  .pet_story {
    width: 310px;
  }

  .pet-pic_story {
    width: 270px;
  }

  button {
    width: 30vw;
  }
}
