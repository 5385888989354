.QueuePage h1 {
  font-size: 25px;
  margin: 20px auto;
  text-align: center;
}

.queue-list li {
  padding: 5px 50px;
  margin: 5px 0;
  border: 1px solid black;
  border-radius: 40px;
}

#showCurrentUser {
  background-color: lightpink;
  color: rgb(56, 56, 56);
  font-weight: 500;
}

@media screen and (max-width: 475px) {
  .QueuePage h1 {
    font-size: 18px;
  }
}
