.AdoptionPage {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 7vh;
}

.available-pet {
  padding-top: 0;
}

.tab {
  width: 250px;
  display: flex;
  justify-content: space-evenly;
}

.tabContent {
  max-width: 500px;
  flex-direction: column;
  align-items: center;
}

.tabContent button {
  width: 130px;
}

.tabContent button:active {
  background-color: pink;
}

.upNext-list {
  align-items: flex-start;
}

.up-next h1 {
  margin-top: 15px;
  text-decoration: underline;
}

@media screen and (max-width: 475px) {
  .AdoptionPage {
    display: flex;
    flex-direction: column;
  }

  .available-pet {
    padding-top: 10px;
  }

  .tab button {
    width: 100px;
  }

  .up-next {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
}
